<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'alunos' })"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Aluno
        </h1>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row>
          <v-col class="pt-0 pb-0" cols="8">
            <ValidationProvider name="Nome completo" rules="required" v-slot="{ errors }">
              <e-label>Nome completo</e-label>
              <v-text-field :error-messages="errors" v-model="form.nomecompleto" dense solo />
            </ValidationProvider>
          </v-col>

          <v-col class="pt-0 pb-0" cols="4">
            <e-label>Número do CPF</e-label>
            <v-text-field v-mask="'###.###.###-##'" v-model="form.cpf" dense solo />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0 pb-0" cols="4">
            <ValidationProvider name="Código do INEP" v-slot="{ errors }">
              <e-label>Identificação única(INEP)</e-label>
              <v-text-field
                :error-messages="errors"
                v-model="form.codigo_inep"
                :loading="pesquisandoINEP"
                dense
                solo
                v-mask="'############'"
                :required="!form.inep_status"
                :rules="[
                  !form.inep_status ? (v) => !!v || 'Preenchimento obrigatório do INEP' : '',
                ]"
                :disabled="form.inep_status"
              >
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col>
            <v-switch cols="2" v-model="form.inep_status" :input-value="form.codigo_inep !== ''">
              <template v-slot:label>Aluno sem INEP</template>
            </v-switch>
          </v-col>
          <v-col class="pt-0 pb-0" cols="3">
            <e-label>Data de Admissão/Data de ingresso</e-label>
            <ValidationProvider
              name="Data de Admissão/Data de ingresso"
              rules="required"
              v-slot="{ errors }"
            >
              <e-date-picker
                :error-messages="errors"
                v-model="form.data_admissao_ingresso"
                placeholder="dd/mm/yyyy"
              ></e-date-picker>
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="3">
            <e-label>Data de nascimento</e-label>
            <!-- <v-text-field :error-messages="errors" v-model="form.data_nascimento" dense solo /> -->
            <ValidationProvider name="Data de nascimento" rules="required" v-slot="{ errors }">
              <e-date-picker
                :error-messages="errors"
                v-model="form.data_nascimento"
                placeholder="dd/mm/yyyy"
              ></e-date-picker>
            </ValidationProvider>
          </v-col>
          <!-- <v-col class="pt-0 pb-0" cols="12">
            <e-label>Anexar Documentos</e-label>
            <v-file-input
              v-model="pdfDocumentos"
              color="orange accent-4"
              counter
              label="Selecionar Arquivo(s)"
              multiple
              placeholder="Selecione seu(s) arquivo(s)"
              prepend-icon="mdi-paperclip"
              outlined
              :show-size="1000"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip v-if="index < 3" color="orange accent-4" dark label small>
                  {{ text }}
                </v-chip>
                <span v-else-if="index === 3" class="text-overline grey--text text--darken-3 mx-2">
                  +{{ pdfDocumentos.length - 2 }} File(s)
                </span>

                <span v-if="form.documento">
                  <v-btn
                    v-if="form.documento && form.documento.length > 0"
                    class="mx-2"
                    fab
                    dark
                    small
                    color="red"
                    @click="downloadDocumento()"
                  >
                    <v-icon dark> mdi-download </v-icon>
                  </v-btn>
                </span>
              </template>
            </v-file-input>
          </v-col> -->
          <v-col class="mb-6" cols="12">
            <e-label>Anexar Documentos</e-label>
            <div class="dropboxStyles">
              <input
                id="fileUpload"
                class="input-file-upload"
                type="file"
                multiple
                @change="selectDocumento"
              />
              <span v-if="!pdfDocumentos.length" style="cursor: pointer">Upload</span>

              <!-- Iterando sobre documentosArray para exibir as descrições -->
              <div
                v-for="(descricao, index) in documentosArray"
                :key="index"
                class="d-flex align-center mb-2"
              >
                <v-chip class="ma-2" color="green" label text-color="white">
                  {{ descricao }}
                </v-chip>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="red"
                  @click="apagarDocumentoForm(pdfDocumentos[index])"
                >
                  <v-icon dark>mdi-close</v-icon>
                </v-btn>
                <v-btn class="mx-2" fab dark small color="blue" @click="downloadDocumento(index)">
                  <v-icon dark>mdi-download</v-icon>
                </v-btn>
              </div>

              <!-- Iterando sobre pdfDocumentos para exibir informações adicionais se necessário -->
              <div
                v-for="(file, index) in pdfDocumentos"
                :key="index"
                class="d-flex align-center mb-2"
              >
                <v-chip class="ma-2" color="green" label text-color="white">
                  {{ file.name }}
                </v-chip>
                <v-btn class="mx-2" fab dark small color="red" @click="apagarDocumento(file)">
                  <v-icon dark>mdi-close</v-icon>
                </v-btn>
                <!-- <v-btn class="mx-2" fab dark small color="blue" @click="downloadDocumento(file)">
                  <v-icon dark>mdi-download</v-icon>
                </v-btn> -->
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="!has_responsavel" class="pt-0 pb-0" cols="6">
            <ValidationProvider name="Filiação 1" rules="required" v-slot="{ errors }">
              <e-label>Filiação 1 (Preferencialmente o nome da Mãe)</e-label>
              <v-text-field :error-messages="errors" v-model="form.filiacao1" dense solo />
            </ValidationProvider>
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Profissão (Filiação 1)</e-label>
            <v-text-field v-model="form.profissao_filiacao1" dense solo />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>RG (Filiação 1)</e-label>
            <v-text-field v-model="form.rg_filiacao1" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>CPF (Filiação 1)</e-label>
            <v-text-field v-model="form.cpf_filiacao1" v-mask="'###.###.###-##'" dense solo />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0 pb-0" cols="12">
            <e-label>Endereço Trabalho (Filiação 1)</e-label>
            <v-text-field v-model="form.logradouro_trabalho_filiacao1" dense solo />
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="!has_responsavel" class="pt-0 pb-0" cols="6">
            <e-label>Filiação 2 (Preferencialmente o nome da Pai)</e-label>
            <v-text-field v-model="form.filiacao2" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Profissão (Filiação 2)</e-label>
            <v-text-field v-model="form.profissao_filiacao2" dense solo />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pt-0 pb-0" cols="12">
            <e-label>Endereço Trabalho (Filiação 2)</e-label>
            <v-text-field v-model="form.logradouro_trabalho_filiacao2" dense solo />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>RG (Filiação 2)</e-label>
            <v-text-field v-model="form.rg_filiacao2" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>CPF (Filiação 2)</e-label>
            <v-text-field v-model="form.cpf_filiacao2" v-mask="'###.###.###-##'" dense solo />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Grau de parentesco </e-label>
            <e-autocomplete
              :items="graus_parentesco"
              :return-object="false"
              :item-value="(value) => value.id"
              :item-text="(value) => value.descricao"
              v-model="form.grau_parentesco_id"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>

          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Responsável Pedagogico</e-label>
            <v-text-field v-model="form.responsavelpedagogico" dense solo />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Endereço Trabalho (Responsável Pedagogico)</e-label>
            <v-text-field v-model="form.logradouro_trabalho_responsavel_pedagogico" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Profissão (Responsável Pedagogico)</e-label>
            <v-text-field v-model="form.profissao_responsavel_pedagogico" dense solo />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>RG (Responsável Pedagogico)</e-label>
            <v-text-field v-model="form.rg_responsavel_pedagogico" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>CPF (Responsável Pedagogico)</e-label>
            <v-text-field
              v-model="form.cpf_responsavel_pedagogico"
              v-mask="'###.###.###-##'"
              dense
              solo
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Sexo </e-label>
            <e-autocomplete
              :items="$constants.sexoList"
              :return-object="false"
              :item-value="(value) => value.id"
              :item-text="(value) => value.descricao"
              v-model="form.sexo_id"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>

          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Cor/Raça</e-label>
            <e-autocomplete
              :items="$constants.corOuRacaList"
              :return-object="false"
              v-model="form.cor_ou_raca"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Nacionalidade</e-label>
            <e-autocomplete
              :items="$constants.nacionalidadeList"
              :return-object="false"
              :item-text="(value) => value"
              :item-value="(value) => value"
              v-model="form.naturalidade"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>País de nacionalidade</e-label>
            <v-text-field v-model="form.nacionalidade" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>UF de nascimento</e-label>
            <e-autocomplete
              :items="$constants.estadosList"
              :return-object="false"
              :item-text="(item) => item.codigo"
              :item-value="(item) => item.codigo"
              v-model="form.estadualidade"
              @change="(event) => atualizarEstado(event)"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Município de nascimento</e-label>
            <e-autocomplete
              :items="municipiosNasc"
              :return-object="false"
              :item-text="(item) => item"
              :item-value="(item) => item"
              v-model="form.municipalidade"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>

          <v-col class="pt-0 pb-0" cols="12">
            <e-label
              >Aluno(a) com deficiência, transtorno do espectro autista ou altas
              habilidades/superdotação</e-label
            >
            <v-switch v-model="form.especial"></v-switch>
          </v-col>
          <template v-if="form.especial">
            <v-col cols="12">
              <h4>
                Tipo de deficiência, transtorno do espectro autista ou altas
                habilidades/superdotação
              </h4>
            </v-col>
            <v-col class="pt-0 pb-0" cols="4">
              <e-label>Deficiência</e-label>

              <e-autocomplete
                clearable
                :items="$constants.tipoDeDeficienciaList"
                :return-object="false"
                v-model="form.tipos_de_deficiencia"
                label="Selecione uma opção"
                multiple
                dense
                solo
              />
            </v-col>
            <v-col class="pt-0 pb-0" cols="4">
              <e-label>Transtorno do espectro autista</e-label>

              <e-autocomplete
                clearable
                :items="$constants.tipoDeTranstornoDoEspectroAutistaList"
                :return-object="false"
                v-model="form.tipos_de_transtornos_gerais"
                label="Selecione uma opção"
                multiple
                dense
                solo
              />
            </v-col>

            <v-col class="pt-0 pb-0" cols="4">
              <e-label>Altas habilidades/superdotação</e-label>

              <e-autocomplete
                clearable
                :items="$constants.tipoDeAltasHabilidadesList"
                :return-object="false"
                v-model="form.tipos_de_altas_habilidades"
                label="Selecione uma opção"
                multiple
                dense
                solo
              />
            </v-col>

            <v-col class="pt-0 pb-0" cols="4">
              <e-label>Transtornos</e-label>

              <e-autocomplete
                clearable
                :items="$constants.transtornosList"
                :return-object="false"
                v-model="form.tipos_de_transtornos_gerais"
                label="Selecione uma opção"
                multiple
                dense
                solo
              />
            </v-col>
          </template>

          <v-col v-if="form.especial" class="mb-6" cols="12">
            <e-label>Laudo Infantil</e-label>
            <div class="dropboxStyles">
              <input id="fileUpload" class="input-file-upload" type="file" @change="selectFile" />
              <span v-if="!pdf" style="cursor: pointer">Upload</span>

              <span v-if="pdf">
                <v-chip v-if="pdf" class="ma-2" color="green" label text-color="white">
                  {{ pdf.target.files[0].name }}
                </v-chip>
              </span>

              <span v-if="form?.laudo && !pdf">
                <v-chip
                  v-if="form?.laudo && !pdf"
                  class="ma-2"
                  color="green"
                  label
                  text-color="white"
                >
                  {{ form?.laudo }}
                </v-chip>
              </span>

              <span v-if="pdf">
                <v-btn v-if="pdf" class="mx-2" fab dark small color="red" @click="apagarLaudo">
                  <v-icon dark> mdi-close </v-icon>
                </v-btn>
              </span>

              <span v-if="form.laudo">
                <v-btn
                  v-if="form.laudo"
                  class="mx-2"
                  fab
                  dark
                  small
                  color="red"
                  @click="apagarLaudoForm"
                >
                  <v-icon dark> mdi-close </v-icon>
                </v-btn>
              </span>

              <span v-if="form.laudo">
                <v-btn
                  v-if="form.laudo"
                  class="mx-2"
                  fab
                  dark
                  small
                  color="red"
                  @click="downloadLaudo"
                >
                  <v-icon dark> mdi-download </v-icon>
                </v-btn>
              </span>
            </div>
          </v-col>

          <v-col class="pt-0 pb-0" cols="12">
            <h4>
              Recursos para uso do(a) aluno(a) em sala de aula e para participação em avaliações do
              Inep (Saeb)
            </h4>
            <v-row class="">
              <v-col
                class="pt-0 pb-0"
                cols="4"
                v-for="(el, index) of $constants.recursosSaebList"
                :key="index"
              >
                <v-checkbox v-model="recursos_saeb[index]" :label="el" :value="el"></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <h4>DOCUMENTO</h4>
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <e-label> RG </e-label>
            <v-text-field v-model="form.registrogeral" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <e-label>Orgão Emissor </e-label>
            <e-autocomplete
              :items="orgao_emissor"
              :return-object="true"
              :item-value="(value) => value.id"
              :item-text="(value) => value.descricao"
              v-model="form.orgaoemissor_id"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>

          <v-col class="pt-0 pb-0" cols="3">
            <e-label> Data expedição (RG)</e-label>
            <e-date-picker v-model="form.rg_dataemissao" placeholder="dd/mm/yyyy"></e-date-picker>
          </v-col>
          <v-col class="pt-0 pb-0" cols="5">
            <e-label> Número de Identificação Social (NIS) </e-label>
            <v-text-field v-model="form.numero_nis" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="7">
            <e-label> Número da matrícula da certidão de nascimento (certidão nova) </e-label>
            <v-text-field
              v-model="form.matricula_certidao_de_nascimento"
              @keyup="preencherCamposCertidao"
              dense
              solo
              maxlength="32"
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="2">
            <e-label> N° Do Livro (certidão)</e-label>
            <v-text-field v-model="form.livro" dense solo disabled />
          </v-col>
          <v-col class="pt-0 pb-0" cols="2">
            <e-label> Folha (certidão)</e-label>
            <v-text-field v-model="form.folha" dense solo disabled />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label> Comarca (certidão)</e-label>
            <v-text-field v-model="form.comarca" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="2">
            <e-label> Data expedição (certidão)</e-label>
            <e-date-picker
              v-model="form.expedicao_certidao"
              placeholder="dd/mm/yyyy"
            ></e-date-picker>
          </v-col>
          <v-col cols="12">
            <h4>DADOS SOBRE A ÁREA RESIDENCIAL DO(A) ALUNO(A)</h4>
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label> País de residência </e-label>
            <v-text-field v-model="form.pais_residencia" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <e-label> CEP </e-label>
            <v-text-field v-mask="`#####-###`" v-model="form.cep" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="2">
            <e-label> UF </e-label>
            <e-autocomplete
              :items="$constants.estadosList"
              :return-object="false"
              :item-text="(item) => item.codigo"
              :item-value="(item) => item.codigo"
              v-model="form.uf_residencia"
              @change="(event) => atualizarEstadoRes(event)"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="3">
            <e-label> Endereço </e-label>
            <v-text-field v-model="form.endereco" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="3">
            <e-label> Número </e-label>
            <v-text-field v-model="form.numero" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="3">
            <e-label> Bairro </e-label>
            <v-text-field v-model="form.bairro" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="3">
            <e-label> Complemento </e-label>
            <v-text-field v-model="form.complemento" dense solo />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label> Município de residência </e-label>
            <e-autocomplete
              :items="municipiosRes"
              :return-object="false"
              :item-text="(item) => item"
              :item-value="(item) => item"
              v-model="form.municipio_residencia"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label> Zona de Residência </e-label>
            <e-autocomplete
              :items="$constants.zonaResidenciaList"
              :return-object="false"
              v-model="form.zona_residencia"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Localização diferenciada de residência</e-label>
            <e-autocomplete
              :items="$constants.localizacaoDiferenciadaDeResidenciaList"
              :return-object="false"
              v-model="form.zona_diferenciada_residencia"
              label="Selecione uma opção"
              dense
              solo
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="6">
            <e-label>Transporte escolar público</e-label>
            <v-switch
              v-model="form.utiliza_transporte_publico"
              color="primary"
              :label="form.utiliza_transporte_publico ? 'Utiliza' : 'Não utiliza'"
              hide-details
            ></v-switch>
          </v-col>

          <v-col class="pt-0 pb-0" cols="2">
            <e-label>Participa de Programas Federais?</e-label>
            <v-switch v-model="form.isProgramasFederais"></v-switch>
          </v-col>
          <template v-if="form.isProgramasFederais">
            <v-col class="pt-0 pb-0" cols="10">
              <e-label>Programas Federais</e-label>

              <e-autocomplete
                clearable
                :items="$constants.programasFederaisList"
                :return-object="false"
                v-model="form.programasFederaisList"
                label="Selecione uma opção"
                multiple
                dense
                solo
              />
            </v-col>
          </template>

          <v-col class="pt-0 pb-0" cols="12">
            <e-label> Observação </e-label>
            <v-textarea name="input-7-1" v-model="form.obs" dense solo></v-textarea>
          </v-col>

          <e-uploads-images
            :dadosImgCarteira="imgCarteiraUrl"
            :dadosImgAluno="imgAlunoUrl"
            @imagem-aluno-form="atualizarImagemAluno($event)"
            @imagem-carteira-form="atualizarImagemCarteira($event)"
          />

          <v-col cols="12">
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              block
              >Enviar Dados</v-btn
            >
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";
import ELabel from "../../components/ELabel.vue";
import EUploadsImages from "../../components/Alunos/EUploadsImages.vue";

const STATUS_INITIAL = 0;
const STATUS_SAVING = 1;
const STATUS_SUCCESS = 2;
const STATUS_FAILED = 3;

export default {
  components: { ELabel, EUploadsImages },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    "form.codigo_inep": function (newVal) {
      if (newVal) {
        this.form.inep_status = false;
      } else {
        this.form.inep_status = true;
      }
    },
    "form.inep_status": function (newVal) {
      if (newVal) {
        this.form.codigo_inep = null;
      }
    },
    "form.grau_parentesco_id": function (newVal) {
      if (newVal === 1) {
        this.form.logradouro_trabalho_responsavel_pedagogico =
          this.form.logradouro_trabalho_filiacao1;
        this.form.rg_responsavel_pedagogico = this.form.rg_filiacao1;
        this.form.cpf_responsavel_pedagogico = this.form.cpf_filiacao1;
      } else if (newVal === 2) {
        this.form.logradouro_trabalho_responsavel_pedagogico =
          this.form.logradouro_trabalho_filiacao2;
        this.form.profissao_responsavel_pedagogico = this.form.profissao_filiacao2;
        this.form.rg_responsavel_pedagogico = this.form.rg_filiacao2;
        this.form.cpf_responsavel_pedagogico = this.form.cpf_filiacao2;
      }
    },
    form: {
      deep: true,
      handler() {
        if (this.nextInepTimeoutCall) {
          clearTimeout(this.nextInepTimeoutCall);
        }

        this.nextInepTimeoutCall = setTimeout(() => {
          this.verificarCodigoINEP();
        }, 500);
      },
    },
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
    selected() {
      return this.form.selected;
    },
    ...mapGetters("Auth", ["user"]),
    documentosArray() {
      // Supondo que form.documento seja uma string separada por vírgulas
      if (typeof this.form.documento === "string") {
        return this.form.documento.split(","); // Transforma a string em array separada por vírgulas
      }
      return this.form.documento; // Se já for uma array, retorna sem modificar
    },
  },

  mounted() {
    this.loadFormData();
  },

  data() {
    return {
      pdf: null,
      pdfDocumentos: [],
      submittingForm: false,
      recursos_saeb: [],
      form: {
        naturalidade: "Brasileira",
        documento: [],
        // outros campos do formulário
        livro: null,
        folha: null,
      },
      nextInepTimeoutCall: null,
      pesquisandoINEP: false,
      municipiosNasc: [],
      municipiosRes: [],
      graus_parentesco: [],
      orgao_emissor: [],
      has_responsavel: false,
      edit: true,
      imgAlunoUrl: null,
      imgAlunoName: null,
      imgCarteiraUrl: null,
      imgCarteiraName: null,
      file_upload: null,
      currentStatus: null,
    };
  },

  methods: {
    atualizarEstado(value) {
      if (value) {
        this.municipiosNasc = this.$constants.municipios[value].cidades;
      }
    },

    preencherCamposCertidao: _.debounce(function (event) {
      const num_certidao = event.target.value;
      this.$set(this.form, "livro", num_certidao.slice(15, 20));
      this.$set(this.form, "folha", num_certidao.slice(20, 23));
    }, 1000),

    atualizarEstadoRes(value) {
      if (value) {
        this.municipiosRes = this.$constants.municipios[value].cidades;
      }
    },

    apagarLaudo() {
      document.querySelector("#fileUpload").value = "";
      this.pdf = null;
    },

    apagarLaudoForm() {
      document.querySelector("#fileUpload").value = "";
      this.pdf = null;
      this.form.laudo = null;
    },

    apagarDocumentoForm() {
      document.querySelector("#fileUpload").value = "";
      this.pdf = null;
      this.form.documento = null;
    },

    apagarDocumento(documento) {
      // console.log(documento);
      const index = this.pdfDocumentos.findIndex((doc) => doc === documento);
      // console.log(index);
      this.pdfDocumentos.splice(index, 1);
    },

    selectFile(event) {
      if (this.pdf && event.target.files[0].type === "application/pdf") {
        this.pdf = null;
      }

      if (event.target.files[0].type === "application/pdf") {
        this.pdf = event;
        return;
      }

      this.$toast.danger("Formato de arquivo Inválido");
    },

    selectDocumento(event) {
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        if (file.type === "application/pdf") {
          this.pdfDocumentos.push(file);
        } else {
          this.$toast.danger(`Formato de arquivo inválido: ${file.name}`);
        }
      }
    },

    async pegarAluno() {
      try {
        const { aluno_id } = this.$route.params;
        const aluno = await this.$services.alunosService.pegarAluno(aluno_id);
        return aluno;
      } catch (error) {
        return this.$handleError(error);
      }
    },

    async downloadLaudo() {
      try {
        const { aluno_id } = this.$route.params;
        const data = await this.$services.alunosService.downloadLaudo(aluno_id);
        return data;
      } catch (error) {
        return this.$handleError(error);
      }
    },

    async downloadDocumento(file) {
      try {
        const { aluno_id } = this.$route.params;
        const data = await this.$services.alunosService.downloadDocumento(aluno_id, file);
        return data;
      } catch (error) {
        return this.$handleError(error);
      }
    },

    async submitForm() {
      this.form.especial = Boolean(this.form.especial);
      this.form.recursos_saeb = this.recursos_saeb;
      this.submittingForm = true;
      this.form.imagem_carteira = this.imgCarteiraName;
      this.form.imagem = this.imgAlunoName;
      this.form.orgaoemissor_id = this.form.orgaoemissor_id?.id ?? null;

      try {
        let alunoResponse;
        if (this.editing) {
          await this.$services.alunosService.atualizarAluno(this.form);
          alunoResponse = { id: this.form.id };
        } else {
          alunoResponse = await this.$services.alunosService.criarAluno(this.form);
        }

        if (this.pdf && alunoResponse) {
          const formData = new FormData();
          formData.append("file", this.pdf.target.files[0]);
          await this.$services.alunosService.uploadLaudo(formData, alunoResponse.id);
        }

        if (this.pdfDocumentos && this.pdfDocumentos.length > 0 && alunoResponse) {
          const formData = new FormData();
          this.pdfDocumentos.forEach((file, index) => {
            formData.append(`files[${index}]`, file);
          });

          await this.$services.alunosService.uploadDocumentos(formData, alunoResponse.id);
        }

        this.$toast.success(
          this.editing ? "Aluno atualizado com sucesso" : "Aluno criado com sucesso"
        );
        this.$router.push({ name: "alunos" });
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },

    async loadFormData() {
      this.graus_parentesco = await this.$services.grauParentescoService.listAll();
      this.orgao_emissor = await this.$services.orgaoEmissorService.listAll();
      const aluno = await this.pegarAluno();
      if (aluno) {
        this.edit = false;
        if (aluno.codigo_inep) {
          this.form.inep_status = false;
        }
      }
      this.atualizarEstadoRes(aluno.uf_residencia);
      this.atualizarEstado(aluno.estadualidade);
      if (aluno.imagem) {
        this.currentStatus = STATUS_SAVING;
      }
      this.recursos_saeb = aluno.recursos_saeb ?? [];

      this.form = {
        ...aluno,
      };

      if (this.form.imagem_carteira) {
        this.imgCarteiraUrl = `${process.env.VUE_APP_API_HOST}carteira-vacina/uploads/${this.form.imagem_carteira}`;
        this.imgCarteiraName = this.form.imagem_carteira;
      }

      if (this.form.imagem) {
        this.imgAlunoUrl = `${process.env.VUE_APP_API_HOST}imagem-do-aluno/uploads/${this.form.imagem}`;
        this.imgAlunoName = this.form.imagem;
      }
    },

    async verificarCodigoINEP() {
      if (this.editing || !this.form.codigo_inep) return;
      this.pesquisandoINEP = true;
      try {
        const aluno = await this.$services.alunosService.getAlunoComINEP(this.form.codigo_inep);
        if (aluno) {
          if (aluno.configuracao_id !== this.user.configuracao_id) {
            this.$modals.warn({
              title: "Você gostaria de transferir esse(a) aluno(a)?",
              message: `Encontramos o(a) aluno(a) <strong>${aluno.nomecompleto}</strong> com INEP <strong>${aluno.codigo_inep}</strong> registrado em outra escola, você gostaria de transferí-lo para sua escola?`,
              buttons: [
                {
                  text: "Cancelar",
                  callback: () => {
                    this.$modals.close();
                  },
                  props: {
                    color: "warning",
                    small: true,
                  },
                },
                {
                  text: "Confirmar",
                  callback: async () => {
                    this.$modals.setOptions({ loading: true });
                    await this.$services.alunosService.atualizarAluno({
                      ...aluno,
                      configuracao_id: this.user.configuracao_id,
                    });
                    this.$modals.close();
                    this.$toast.success("Aluno transferido com sucesso!");
                    this.$router.push({ name: "alunos" });
                  },
                  props: {
                    color: "success",
                    // text: true,
                    small: true,
                  },
                },
              ],
            });
          } else {
            this.$modals.info({
              title: "Este(a) aluno(a) já está cadastrado na sua escola!",
              message: "Verificamos que este(a) aluno(a) já está cadastrado na sua escola, ",
              buttons: [
                {
                  text: "Confirmar",
                  callback: () => {
                    this.$modals.close();
                    this.$toast.info("Este(a) aluno(a) já está cadastrado na sua escola");
                    this.$router.push({ name: "alunos" });
                  },
                  props: {
                    color: "success",
                    small: true,
                  },
                },
              ],
            });
          }
        }
      } catch (error) {
        this.$handleError(error);
      }
      this.pesquisandoINEP = false;
    },
    atualizarImagemAluno(event) {
      this.imgAlunoUrl = event.url;
      this.imgAlunoName = event.name;
    },

    atualizarImagemCarteira(event) {
      this.imgCarteiraUrl = event.url;
      this.imgCarteiraName = event.name;
    },
  },
};
</script>

<style scoped>
.dropboxStyles {
  outline: 2px dashed grey; /* the dash box */
  background-color: rgb(246, 255, 224);
  padding: 10px 10px;
  color: dimgray;
  cursor: pointer;
}
.input-file-upload {
  opacity: 0;
  width: 100%;
  height: 60px;
  position: absolute;
  cursor: pointer;
}
</style>
